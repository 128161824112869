@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');

main{
    background-color: #fff;
}

.nwHeader {
    background-color: #ffffff;
    padding: 15px 15px;
    position: sticky;
    top: 0px;
    z-index: 99;
}
.mwLogo img {
    max-height: 150px;
    width: auto;
}
.iv-top {
    background: #fff;
}
.iv-title {
    font-size: 33px;
    font-weight: bold;
    color: #000;
    padding-top: 33px;
    font-family: 'Montserrat', sans-serif;
}
.iv-sub-title {
    font-size: 20px;
    font-weight:normal;
    color: #000;
    padding-top: 33px;
    padding-bottom: 18px;
    font-family: 'Montserrat', sans-serif;
}
.blue-line {
    width: 120px;
    height: 5px;
    margin: 5px auto 24px;
    background-color: #37a8df;
    border: 0 none;
    border-radius: 10px;
}
.opt-box{
    margin-bottom: 50px;
}
.opt-box .wrapper{
    display: flex;
    justify-content: center;
}
.opt-box .wrapper .cmn-box{
    border: 1px solid #008BD4;
    border-radius: 10px;
    text-align: center;
    width: 250px;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px 15px;
    background: #fff;
}
.opt-box .wrapper .cmn-box:hover{
    box-shadow: 0px 0px 30px -3px rgb(0,139,212,0.4);
-webkit-box-shadow: 0px 0px 30px -3px rgb(0,139,212,0.4);
-moz-box-shadow: 0px 0px 30px -3px rgb(0,139,212,0.4);
transition: 200ms ease-in-out;
}

.opt-box .wrapper .cmn-box a{
    text-decoration: none;
    width: 100%;
}
.opt-box .wrapper .cmn-box .box-title{
    color: #000;
    font-size: 30px;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    margin-top: 17px;
}

@media screen and (max-width:767.5px){
    .opt-box .wrapper{
        flex-direction: column;
        align-items: center;
    }
}

/* Find Account Page Css */

.locateAccount {
    max-width: 850px;
    text-align: center;
    margin: auto;
}

.locateAccount .form-control{
    border: 1px solid #008BD4;
    padding: 9px 16px;
    max-width: 500px;
    margin: auto;
    border-radius: 10px;
}

.locateAccount p{
    font-size: 20px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
}

.locateAccount ::placeholder{
    font-size: 16px;
    color: #ADB5BD;
    font-weight: 300;
    font-family: 'Montserrat', sans-serif;
}

.locateAccount .btn {
    background: #28A745;
    color: #fff;
    padding: 16px 24px;
    border-radius: 10px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    margin-top: 30px;
    padding: 20px 70px !important;
}
.locateAccount .btn:disabled {
    background: #28A745;
    color: #fff;
    padding: 16px 24px;
    border-radius: 10px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    margin-top: 30px;
    padding: 20px 70px !important;
    pointer-events: none;
}
