.breadcrumb-item a {
    color: #37a8df;
    text-decoration: none;
}

.breadcrumb-item.active {
    color: #6a6a6a;
}

.breadcrumb-item+.breadcrumb-item::before {
    content: ">" !important;
}
.home-icons{
    margin-top: 50px;
    margin-bottom: 50px;
}
.has-search {
    width: 50% !important;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 30px;
}

.has-search .fa {
    color: #37a8df;
}

.has-search .form-control {
    padding-left: 2.375rem;
    font-size: 18px;
    font-weight: 400;
    color: #000;
    height: 48px;
}

.has-search .form-control:focus {
    border-color: #37a8df !important;
    outline: 0 !important;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px #37a8df !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px #37a8df !important;
    color: #333 !important;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}

.has-search .btn {
    background-color: #37a8df !important;
    border-radius: 6px;
    border: none !important;
    color: #fff !important;
    font-weight: 400;
    width: 100%;
    height: 48px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 18px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
}

.choice-box {
    width: 200px;
    height: 200px;
    margin: 10px auto;
    padding: 20px 10px;
    border-radius: 5px;
    background-color: #fff;
    color: #333;
    cursor: pointer;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .1);
}

.choice-box:hover {
    background-color: #37a8df;
    color: #fff;
}

.choice-box:hover img {
    /* filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(297deg) brightness(101%) contrast(101%); */
    webkit-filter: invert(1);
    filter: invert(1);
}

.choice-box img {
    width: 85px;
    height: auto;
    margin-bottom: 15px;
}

.choice-box h2 {
    font-size: 20px;
    font-weight: 500;
}

/* .hide-on-desk {
    display: none;
} */

.choice .btn-mob {
    display: flex;
    align-items: center;
    padding: 8px 0 !important;
    height: 65px;
    border-radius: 6px;
    margin-top: 0;
    text-align: left;
    font-size: 5.5vw;
    background: #37a8df;
    border: 2px solid #37a8df !important;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .1) !important;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .1) !important;
    width: 100%;
    margin-bottom: 15px;
}

.choice .btn-mob:hover {
    background: #37a8df;
    border: 2px solid #37a8df !important;
}

.choice .btn-mob img {
    webkit-filter: invert(1);
    filter: invert(1);
}

.choice .btn-mob p {
    text-align: left;
    font-size: 22px;
    margin-bottom: 0px;
    color: #fff;
    font-weight: 700;
}

.mob-home-btn-icon {
    width: 38px !important;
    display: inline-block;
    margin: auto 12px !important;
}

.comp-btn {margin-top: 100px;margin-bottom: 50px;}

.comp-btn .btn-complete{
    width: 250px;
    padding: 20px 26px;
    font-size: 20px;
    font-weight: 600;
    color: #19213D;
    background: #F6F8FC;
    border-radius: 12px;
    margin-left: 10px !important;
    margin-right: 10px !important;
    text-transform: uppercase;
}

.comp-btn .container {
    justify-content: center;
    
}

@media screen and (max-width:991px) {
    .has-search {
        width: 100% !important;
    }
}

@media screen and (max-width:767.5px) {

    .choice {
        width: 100% !important;
    }
}

.rq-heading {
    margin: 20px 0 0 0;
    text-align: center;
    font-size: 16pt;
    font-weight: 400;
    margin-bottom: 12px;
}

.af-box {
    max-width: 80%;
    margin: auto;
}

.answer-faqs{
    text-align: center;
}

.af-title h2{
    color: #333;
    margin-top: 45px;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 40px;
}

.af-body p{
    font-size: 20px;
    color: #6a6a6a;
}

.af-body p a{
    color: #37a8df;
    text-decoration: none;
}

.thumb {
    width: 100%;
    text-align: center;
    margin-top: 26px;
}

.thumb span {
    background: #f3f9e8;
    font-size: 16px;
    padding: 7px 15px 7px 15px;
    color: #37a8df;
    border-radius: 5px;
}

.answer .question-answered {
    color: #37a8df;
}

.q_links{
    text-decoration: none!important;
    background-color: transparent;
}

.svg-inline--fa.fa-w-16 {
    width: 1em;
    overflow: visible;
    margin-right: 10px;
}

.des-yes-no p{
    padding: 0;
    font-size: 16px;
    font-weight: 700;
    color: #333;
    margin: 20px 0px;
}

.ld-btn {
    display: flex;
    justify-content: center;
}

.ld-btn .cmn-btn {
    position: relative;
    margin: 0;
    border: 2px solid #aaa!important;
    margin-top: 6px;
    padding: 6px 20px 6px 60px;
    color: #8a8a8a;
    font-size: 18px;
    font-weight: 700;
    background-color: #fff;
    border-radius: 0.25rem;
    margin-left : 20px;
    margin-right : 20px;
}

.no-svg, .yes-svg {
    position: absolute!important;
    top: 0;
    bottom: 0;
    padding: 0 6px;
    padding-top: 5px;
    background: #8a8a8a;
    left: 0!important;
}

.yes-svg {
    background: #37a8df;
    left: 0;
}

.no-svg svg, .yes-svg svg{
    color: #fff;
    width: 1em;
    display: inline-block;
    margin-right: 3px;
    margin-left: 3px;
}

.svg-inline--fa {
    display: inline-block;
    font-size: inherit;
    height: 1em;
    vertical-align: -0.125em;
    width: 1em;
    overflow: visible;
}

.ld-btn .like-btn {
    position: relative;
    color: #37a8df !important;
    border-color: #37a8df !important;
    margin: 0;
    padding-top: 6px;
    padding-bottom: 6px;
    margin-top: 6px;
    border: 2px solid #37a8df!important;
}

.fa-search {
    position: absolute;
    z-index: 9999 !important;
    top: 7px;
    left: 4px;
    color: #37a8df;
}

.fa-search svg{
    font-size: 20px;
}
.like-icon{
    color: grey;
    font-size: 20px;
}

.like-icon:hover{
    color:#37a8df;
}
.like-btn-icon {
    color: white;
}

.like-btn-icon:hover{
    color:#02496d;

}



@media screen and (max-width:767.5px) {
    .af-title h2{
        font-size: 25px;
    }
    .af-body p{
        font-size: 16px;
    }
    .comp-btn{
        margin-top: 25px;
    }
}


@media screen and (max-width: 480.5px){
    .has-search .form-control{
        font-size: 13px;
    }
    .has-search .btn{
        font-size: 13px;
    }
}