@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');


main {
    background-color: #fff;
    min-height: 60vh !important;
}

.nwHeader {
    background-color: #ffffff;
    padding: 15px 15px;
    position: sticky;
    top: 0px;
    z-index: 99;
}

.mwLogo img {
    max-height: 150px;
    width: auto;
}

.iv-top {
    background: #fff;
}

.iv-title {
    font-size: 33px;
    font-weight: bold;
    color: #000;
    padding-top: 33px;
    font-family: 'Montserrat', sans-serif;
}

.iv-sub-title {
    font-size: 20px;
    font-weight: normal;
    color: #000;
    padding-top: 33px;
    padding-bottom: 18px;
    font-family: 'Montserrat', sans-serif;
}

.blue-line {
    width: 120px;
    height: 5px;
    margin: 5px auto 24px;
    background-color: #37a8df;
    border: 0 none;
    border-radius: 10px;
}

.opt-box {
    margin-bottom: 50px;
}

.opt-box .wrapper {
    display: flex;
    justify-content: center;
}

.opt-box .wrapper .cmn-box {
    border: 1px solid #008BD4;
    border-radius: 10px;
    text-align: center;
    width: 250px;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px 15px;
    background: #fff;
}

.opt-box .wrapper .cmn-box:hover {
    box-shadow: 0px 0px 30px -3px rgb(0, 139, 212, 0.4);
    -webkit-box-shadow: 0px 0px 30px -3px rgb(0, 139, 212, 0.4);
    -moz-box-shadow: 0px 0px 30px -3px rgb(0, 139, 212, 0.4);
    transition: 200ms ease-in-out;
}

.opt-box .wrapper .cmn-box a {
    text-decoration: none;
    width: 100%;
}

.opt-box .wrapper .cmn-box .box-title {
    color: #000;
    font-size: 30px;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    margin-top: 17px;
}

@media screen and (max-width:767.5px) {
    .opt-box .wrapper {
        flex-direction: column;
        align-items: center;
    }
}

/* Find Account Page Css */

.locateAccount {
    width: 100%;
    text-align: center;
}

.locateAccount .form-control {
    border: 1px solid #008BD4;
    padding: 9px 16px;
    max-width: 500px;
    margin: auto;
    border-radius: 10px;
}

.locateAccount p {
    font-size: 20px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
}

.locateAccount ::placeholder {
    font-size: 16px;
    color: #ADB5BD;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
}

.locateAccount .btn {
    background: #28A745;
    border: 1px solid #28A745;
    color: #fff;
    padding: 16px 24px;
    border-radius: 10px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
}

.locateAccount .btn:hover {
    background: #00791c;
    border: 1px solid #00791c;
    color: #fff;
}


/* CSS for new page date 20-11-2023 */

.order-div .cmn-box {
    display: flex;
    justify-content: space-between;
    background-color: #F6F8FC;
    padding: 16px 16px;
    font-family: 'Inter', sans-serif;
    border-radius: 10px;
    margin-bottom: 15px;
    box-shadow: 0px 5px 3px -3px rgba(0, 0, 0, 0.1);
}

.order-div .cmn-box .order-image {
    width: 15%;
    text-align: center;
}

.order-div .cmn-box .order-content {
    width: 82%;
}

.oc-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.oc-div-left .product-name {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: #19213D;
}

.oc-div-left .product-price {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #19213D;
}

.oc-div-left .quantity {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #5D6481;

}

.oc-div-left .quantity span {
    color: #19213D;
}

.oc-div-right .ot-purchase {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #5D6481;
}

.oc-div-right .gh-btn {
    padding: 5px 24px;
    text-transform: uppercase;
    color: #fff;
    background: #28A745;
    font-size: 18px;
}

.oc-div-02 {
    justify-content: flex-end;
}

.oc-div-02 .oc-div-left {
    margin-right: 20px;
}

.oc-div-02 .oc-div-right {
    text-align: right;
}

.sub-total,
.shipping,
.paid-amount,
.refund {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #5D6481;
}

.sub-total span,
.shipping span,
.paid-amount span,
.refund span {
    color: #19213D;
}

.oc-div-03 {
    align-items: self-start;
}

.oc-div-03 .order-status {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: #19213D;
}

.oc-div-03 .os-detail {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: #19213D;
}

.oc-div-03 .oc-div-right {
    display: flex;
}

.oc-div-03 .oc-div-right .total-amount {
    margin-right: 20px;
}

.oc-div-03 .oc-div-right .total-amount,
.oc-div-03 .oc-div-right .total-paid {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #5D6481;
}

.oc-div-03 .oc-div-right .total-amount span,
.oc-div-03 .oc-div-right .total-paid span {
    color: #19213D;
}

.red-dot {
    width: 12px;
    height: 12px;
    background-color: #FF0000;
    border-radius: 50%;
    float: left;
    position: relative;
    top: 4px;
    margin-right: 10px;
}

.orange-dot {
    width: 12px;
    height: 12px;
    background-color: #FFC700;
    border-radius: 50%;
    float: left;
    position: relative;
    top: 4px;
    margin-right: 10px;
}

.blue-dot {
    width: 12px;
    height: 12px;
    background-color: #0057FF;
    border-radius: 50%;
    float: left;
    position: relative;
    top: 4px;
    margin-right: 10px;
}

.green-dot {
    width: 12px;
    height: 12px;
    background-color: #00BA13;
    border-radius: 50%;
    float: left;
    position: relative;
    top: 4px;
    margin-right: 10px;
}

@media screen and (max-width:767.5px) {
    .oc-div-02 {
        justify-content: flex-start;
    }

    .oc-div-03 {
        flex-direction: column-reverse;
    }
}

@media screen and (max-width:550px) {
    .oc-div-01 {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 15px;
    }

    .oc-div-02 {
        justify-content: space-between;
    }
}


@media screen and (max-width:450px) {
    .oc-div-02 {
        flex-direction: column;
        align-items: flex-start;
    }

    .oc-div-02 .oc-div-right {
        text-align: left;
    }

}

.custom-btn .btn {
    display: block;
    width: 785px;
    margin: auto;
    padding: 28px 16px;
    background: #F6F8FC;
    border-radius: 10px;
    font-size: 30px;
    font-weight: 600;
    box-shadow: 0px 5px 3px -3px rgba(0, 0, 0, 0.1);
}

.custom-btn .btn:hover {
    background: #d8d8d8;

}

/* css for orders page 28 nov 2023 */

.order-div .cmn-box img{
    width: 160px;
    height: auto;
}
.order-div .cmn-box .oc-div-left {
    text-align: left;
}
.order-div .cmn-box .order-content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media screen and (max-width:1024px){
    .order-div .cmn-box .order-image {
        margin-right: 15px;
    }
}
@media screen and (max-width:767.5px){
    .oc-div-right .gh-btn{font-size: 16px;}
}

.total-amount, .total-paid {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #5D6481;
}

.total-amount span, .total-paid span{
    color: #19213D;
}

.btn-complete {
    display: block;
    width: 700px;
    margin: auto;
    margin-left: auto !important;
    margin-right: auto !important;
    padding: 1rem;
    background: #F6F8FC;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 600;
    box-shadow: 0px 5px 3px -3px rgba(0, 0, 0, 0.1);
}