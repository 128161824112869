main {
    background-color: #fff;
}

.pagePadding{
    padding-top: 50px;
    padding-bottom: 50px;
    min-height: 60vh;
}
.msgDisplaySec, .pagePadding {
    min-height: calc(100vh - 230px);
}
.nwHeader {
    background-color: #ffffff;
    padding: 15px 15px;
    position: sticky;
    top: 0px;
    z-index: 99;
}

.mwLogo img {
    max-height: 150px;
    width: auto;
}

.iv-top {
    background: #fff;
    padding-left: 10px;
    padding-right: 10px;
}

.iv-title {
    font-size: 33px;
    font-weight: bold;
    color: #000;
    font-family: 'Montserrat', sans-serif;
    padding-top: 0;
    text-transform: capitalize;
}

.iv-sub-title {
    font-size: 20px;
    font-weight: normal;
    color: #000;
    padding-top: 33px;
    padding-bottom: 18px;
    font-family: 'Montserrat', sans-serif;
}

.blue-line {
    width: 120px;
    height: 5px;
    margin: 5px auto 24px;
    background-color: #37a8df;
    border: 0 none;
    border-radius: 10px;
}

.opt-box {
    margin-bottom: 50px;
}

.opt-box .wrapper {
    display: flex;
    justify-content: center;
    /* gap: 30px; */
}

.opt-box .wrapper .cmn-box {
    border: 1px solid #008BD4;
    border-radius: 10px;
    text-align: center;
    width: 250px;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px 15px;
    background: #fff;
    cursor: pointer;
}

.opt-box .wrapper .cmn-box:hover {
    box-shadow: 0px 0px 30px -3px rgb(0, 139, 212, 0.4);
    -webkit-box-shadow: 0px 0px 30px -3px rgb(0, 139, 212, 0.4);
    -moz-box-shadow: 0px 0px 30px -3px rgb(0, 139, 212, 0.4);
    transition: 200ms ease-in-out;
}

.opt-box .wrapper .cmn-box a {
    text-decoration: none;
    width: 100%;
}

.opt-box .wrapper .cmn-box .box-title {
    color: #000;
    font-size: 30px;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    margin-top: 17px;
}

@media screen and (max-width:767.5px) {
    .opt-box .wrapper {
        flex-direction: column;
        align-items: center;
    }
}

/* Find Account Page Css */

.locateAccount {
    width: 100%;
    text-align: center;
}

.locateAccount .form-control {
    border: 1px solid #008BD4;
    padding: 9px 16px;
    max-width: 500px;
    margin: auto;
    border-radius: 10px;
}

.locateAccount p {
    font-size: 20px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
}

.locateAccount ::placeholder {
    font-size: 16px;
    color: #ADB5BD;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
}

.locateAccount .btn {
    background: #28A745;
    border: 1px solid #28A745;
    color: #fff;
    padding: 16px 24px;
    border-radius: 10px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
}

.locateAccount .btn:hover {
    background: #00791c;
    border: 1px solid #00791c;
    color: #fff;
}


/* CSS for new page date 20-11-2023 */

.order-div .cmn-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F6F8FC;
    padding: 16px 16px;
    font-family: 'Inter', sans-serif;
    border-radius: 10px;
    margin-bottom: 15px;
    box-shadow: 0px 5px 3px -3px rgba(0, 0, 0, 0.1);
}

.order-div .cmn-box .order-image {
    width: 15%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.order-div .cmn-box .order-content {
    width: 82%;
}

.oc-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.oc-div-left .product-name {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: #19213D;
}

.oc-div-left .product-price {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #19213D;
}

.oc-div-left .quantity {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #5D6481;

}

.oc-div-left .quantity span {
    color: #19213D;
}

.oc-div-right .ot-purchase {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #5D6481;
}

.oc-div-right .gh-btn {
    padding: 5px 24px;
    text-transform: uppercase;
    color: #fff;
    background: #28A745;
    font-size: 18px;
    white-space: nowrap;
}

.oc-div-02 {
    justify-content: space-between;
}

.oc-div-02 .oc-div-left {
    margin-right: 20px;
}

.oc-div-02 .oc-div-right {
    text-align: right;
}

.sub-total,
.shipping,
.paid-amount,
.refund {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #5D6481;
}

.sub-total span,
.shipping span,
.paid-amount span,
.refund span {
    color: #19213D;
}

.oc-div-03 {
    align-items: self-start;
}

.oc-div-03 .order-status {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: #19213D;
}

.oc-div-03 .os-detail {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: #19213D;
}

.oc-div-03 .oc-div-right {
    display: flex;
}

.oc-div-03 .oc-div-right .total-amount {
    margin-right: 20px;
}

.oc-div-03 .oc-div-right .total-amount,
.oc-div-03 .oc-div-right .total-paid {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #5D6481;
}

.oc-div-03 .oc-div-right .total-amount span,
.oc-div-03 .oc-div-right .total-paid span {
    color: #19213D;
}

.red-dot {
    width: 12px;
    height: 12px;
    background-color: #FF0000;
    border-radius: 50%;
    float: left;
    position: relative;
    top: 4px;
    margin-right: 10px;
}

.orange-dot {
    width: 12px;
    height: 12px;
    background-color: #FFC700;
    border-radius: 50%;
    float: left;
    position: relative;
    top: 4px;
    margin-right: 10px;
}

.blue-dot {
    width: 12px;
    height: 12px;
    background-color: #0057FF;
    border-radius: 50%;
    float: left;
    position: relative;
    top: 4px;
    margin-right: 10px;
}

.green-dot {
    width: 12px;
    height: 12px;
    background-color: #00BA13;
    border-radius: 50%;
    float: left;
    position: relative;
    top: 4px;
    margin-right: 10px;
}

@media screen and (max-width:767.5px) {
    .oc-div-02 {
        justify-content: flex-start;
    }

    .oc-div-03 {
        flex-direction: column-reverse;
    }
}

@media screen and (max-width:550px) {
    .oc-div-01 {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 15px;
    }

    .oc-div-02 {
        justify-content: space-between;
    }
}


@media screen and (max-width:450px) {
    .oc-div-02 {
        flex-direction: column;
        align-items: flex-start;
    }

    .oc-div-02 .oc-div-right {
        text-align: left;
    }

}

.custom-btn .btn {
    display: block;
    width: auto;
    margin: auto;
    padding: 15px 20px;
    background: #37a8dfff !important;
    color: #fff !important;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 600;
    box-shadow: 0px 5px 3px -3px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    min-width: 120px;
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.custom-btn .btn:hover {
    background: #d8d8d8;

}
.cancleOrderPage .custom-btn,
.orderHelpPage .custom-btn {
    flex-direction: column;
    max-width: 450px;
    margin: 0 auto;
}

/* css for orders page 28 nov 2023 */

.order-div .cmn-box img {
    width: 160px;
    height: auto;
}

.order-div .cmn-box .oc-div-left {
    text-align: left;
    padding-bottom: 0 !important;
}
.rev-order-div .cmn-box .oc-div-left {
    padding-bottom: 0 !important;
}
.order-div .cmn-box .oc-div-left h3{
    font-size: 22px;
}
.order-div .cmn-box .order-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media screen and (max-width:1024px) {
    .order-div .cmn-box .order-image {
        margin-right: 15px;
    }
}

@media screen and (max-width:767.5px) {
    .oc-div-right .gh-btn {
        font-size: 14px;
    }
}
.total-amount, .total-paid {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #5D6481;
}

.total-amount span, .total-paid span{
    color: #19213D;
}

.Order-product-name, .order-product-price {
    font-size: 29px;
    font-weight: 700;
    color: #fff;
}
.rev-order-div .cmn-box{
    background-color: #28A745;
    padding: 25px 25px;
}
.rev-order-div .cmn-box .order-image {
    display: flex;
    align-items: center;
    justify-content: center;
}
.comp-order {
    color: #fff;
}
.pkg-opt {
    text-align: left;
}
.pkg-opt h3{
    font-size: 29px;
    font-weight: 700;
    color: #000;
}
.pkg-opt h4{
    font-size: 29px;
    color: #000;
}

.select-pkg .Order-product-name, .select-pkg .order-product-price {
    font-size: 29px;
    font-weight: 700;
    color: #000;
}
.rev-order-div .cmn-box{
    background-color: #28A745;
    padding: 25px 25px;
}
.select-pkg .comp-order {
    color: #000;
}
.pkg-opt {
    text-align: left;
}
.pkg-opt h3{
    font-size: 29px;
    font-weight: 700;
    color: #000;
}
.pkg-opt h4{
    font-size: 29px;
    color: #000;
}
.select-pkg .oc-div-03 {
    align-items: center;
}
.select-pkg .oc-div-03 .oc-div-left{
    color: #19213D;
    font-size: 14px;
    font-weight: 700;
}

.bk-btn .btn{
    padding: 20px 30px;
    text-transform: uppercase;
    color: #19213D;
    font-size: 20px;
    font-weight: 700;
    background: #e2e2e2;
    margin-bottom: 30px;
    margin-top: 30px;
    min-width: 300px;
}
.bk-btn .btn:hover{
    background: #b8b8b8;
}

.border{
    border: 2px solid #03101c!important;
};

.pkg-btn a{
    background-color: #28A745;
    padding: 25px 25px;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
};


.membershipDisplay {
    width: 100%;
    max-width: 450px;
}

.faq-choice{
    margin-bottom: 30px;
}
.faq-choice .card-title {
    font-size: 22px;
}

.container.pagePadding.orderHelpPage,
.cancleOrderPage {
    min-height: 70vh;
}

@media screen and (max-width: 1024.5px){
    .opt-box .wrapper{
        flex-wrap: wrap;
    }
    .opt-box .wrapper > .cmn-box{
        width: 300px;
        max-width: 50%;
    }
    .msgDisplaySec, .pagePadding {
        min-height: calc(100vh - 290px);
    }
}


@media screen and (max-width: 767.5px) {
    .pagePadding{
        padding-top: 55px;
        padding-bottom: 35px;
        min-height: 45vh;
    }
    .iv-sub-title {
        font-size: 15px;
    }
    .custom-btn .btn{
        font-size: 20px;
    }
    h1 {
        font-size: 25px !important;
    }
    .oc-div-right .ot-purchase {
        text-align: left;
    }
    .Order-product-name, .order-product-price{
        font-size: 22px;
    }
    .orderHelpPage .custom-btn .btn{
        width: 100%;
    }
    .comp-order {
        font-size: 14px;
    }
    .oc-div.oc-div-01 {
        margin: 0;
        margin-bottom: 15px;
    }
    .order-div .cmn-box .oc-div-left h3 {
        font-size: 18px;
        line-height: 26px;
    }
    
    .bk-btn .btn {
        font-size: 18px;
        padding: 20px 15px;
        min-width: 165px;
        margin-bottom: 10px;
    }
    .comp-btn > .d-flex {
        flex-wrap: wrap;
    }
    .comp-btn .btn-complete {
        width: 100% !important;
        font-size: 18px !important;
    }
    .opt-box .wrapper > .cmn-box{
        max-width: 100%;
        width: 300px;
    }
    .oc-div-03 .os-detail{
        font-size: 16px;
    }
    .oc-div-02{
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .pkg-opt h4 {
        font-size: 18px;
    }
    .order-div .cmn-box .oc-div.oc-div-02 {
        margin-top: 0;
    }
    .cancleOrderPage .custom-btn .btn, .orderHelpPage .custom-btn .btn{
        margin: 15px 0 !important;
    }
}

@media screen and (max-width: 480.5px){
    .oc-div-03 .os-detail{
        font-size: 12px;
    }
    .oc-div-03 .os-detail{
        font-size: 14px;
    }
    .oc-div-02 .oc-div-left{
        text-align: left;
    }
    .opt-box .wrapper > .cmn-box{
        width: 250px;
        padding: 10px;
        margin: 0 auto 30px;
       
    }
    .opt-box .wrapper .cmn-box .box-title{
        font-size: 18px !important;
    }
   
}