
.f_quick_links {
    padding: 0;
    margin: 0 0 5px 0;
}

.f_quick_links li {
    display: inline-block;
    padding: 0 5px;
    font-size: 20px;
    color: #000;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    list-style: none;
}

.f_quick_links li a {
    color: #000;
    text-decoration: none;
}

.nwFootter {
    background: #F2F2F2;
}

.nwFootter_content {
    position: relative;
    padding: 45px 0;
    color: #000;
}

.nwFootter_content p {
    color: #000;
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    margin-bottom: 5px;
}

.nwFootter_content p a {
    color: #000;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.link-btn {
    color: #8a8a8a !important;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
}

.link-btn:hover {
    color: #37a8df !important;
}


.user-sec-btn {
    position: relative;
}

.icon {
    background-color: rgba(200, 200, 200);
    padding: 2px 5px;
    width: fit-content;
}

.user-sec-btn:hover .popup {
    opacity: 1;
    display: block;
}

.popup {
    padding: 10px 0;
    min-width: 160px;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,.1);
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.1);
    z-index: 1;
    background-color: #f9f9f9;
    position: absolute;
    top: 25px;
    right: 0px;
    transition: all 0.25s ease;
    opacity: 0;
    display: none;
    text-align: center;
}
.popup-btn{
    margin-bottom: 0;
}
.user-sec-btn .popup a{
    padding: 5px 16px 5px 21px;
    display: block;
    text-decoration: none;
    color: #8a8a8a !important;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
}

.user-sec-btn .popup a:hover{
    color: #37a8df !important;
}


@media screen and (max-width: 767.5px) {
    .f_quick_links li,
    .nwFootter_content p{
        font-size: 16px;
    }
}

@media screen and (max-width: 360.5px) {
    .f_quick_links li,
    .nwFootter_content p{
        font-size: 14px;
    }
}