@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');

main{
    background-color: #fff;
}

.nwHeader {
    background-color: #ffffff;
    padding: 15px 15px;
    position: sticky;
    top: 0px;
    z-index: 99;
    box-shadow: 0 10px 10px rgba(0,0,0,0.03);
    border-bottom: 1px solid #cccccc2b;
}
.nwHeader .col-left-inner {
    display: flex;
}
.languageDiv{
    padding-left: 30px;
    display: flex;
    align-items: center;
}
.languageDiv h5 {
    padding-top: 0 !important;
    font-size: 15px;
    margin: 0 10px 0 0;
}
.link-btn{
    font-size: 14px !important;
    display: inline-block;
    padding: 0;
    line-height: 2;
}
.mwLogo img {
    max-height: 80px !important;
    width: auto;
}
.goog-te-gadget{
    font-size: 0 !important;
}
#google_translate_element select {
    font-size: 16px;
}
.iv-top {
    background: #fff;
}
.iv-title {
    font-size: 33px;
    font-weight: bold;
    color: #000;
    font-family: 'Montserrat', sans-serif;
}
.iv-sub-title {
    font-size: 20px;
    font-weight:normal;
    color: #000;
    padding-top: 33px;
    padding-bottom: 18px;
    font-family: 'Montserrat', sans-serif;
}
.blue-line {
    width: 120px;
    height: 5px;
    margin: 5px auto 24px;
    background-color: #37a8df;
    border: 0 none;
    border-radius: 10px;
}
.opt-box{
    margin-bottom: 50px;
}
.opt-box .wrapper{
    display: flex;
    justify-content: center;
}
.opt-box .wrapper .cmn-box{
    border: 1px solid #008BD4;
    border-radius: 10px;
    text-align: center;
    width: 250px;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px 15px;
    background: #fff;
}
.opt-box .wrapper .cmn-box:hover{
    box-shadow: 0px 0px 30px -3px rgb(0,139,212,0.4);
-webkit-box-shadow: 0px 0px 30px -3px rgb(0,139,212,0.4);
-moz-box-shadow: 0px 0px 30px -3px rgb(0,139,212,0.4);
transition: 200ms ease-in-out;
}

.opt-box .wrapper .cmn-box a{
    text-decoration: none;
    width: 100%;
}
.opt-box .wrapper .cmn-box .box-title{
    color: #000;
    font-size: 22px !important;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    margin-top: 17px;
}
.membershipDisplay {
    width: 100%;
    max-width: 450px;
    padding-left: 10px;
    padding-right: 10px;
}
.membershipDisplay div:has(img) {
    display: flex;
    align-items: center;
    justify-content: center;
}


@media screen and (max-width:1024.5px){
    .languageDiv h5{
        font-size: 13px;
    }
    #google_translate_element select {
        font-size: 14px;
    }
}

@media screen and (max-width:767.5px){
    .opt-box .wrapper{
        flex-direction: column;
        align-items: center;
    }
   
}

/* Find Account Page Css */

.locateAccount {
    max-width: 850px;
    text-align: center;
}

.locateAccount .form-control{
    border: 1px solid #008BD4;
    padding: 9px 16px;
    max-width: 500px;
    margin: auto;
    border-radius: 10px;
}

.locateAccount p{
    font-size: 20px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
}

.locateAccount ::placeholder{
    font-size: 16px;
    color: #ADB5BD;
    font-weight: 300;
    font-family: 'Montserrat', sans-serif;
}

.locateAccount .btn {
    background: #28A745;
    color: #fff;
    padding: 16px 24px;
    border-radius: 10px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
}
.wrapper{
    display: flex;
}

@media screen and (max-width: 1024.5px) {
    
    .languageDiv {
        padding-left: 15px;
    }
}


@media screen and (max-width: 767.5px) {
    .nwHeader {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .nwHeader .col-md-2.text-center {
        order: -1;
        margin-bottom: 10px;
    }
    .main-menu-btn span {
        display: none;
    }
    .main-menu-btn span {
        display: none;
    }
    .mwLogo img {
        max-height: 60px !important;
    }
    .nwHdr-top .col-md-5.text-left,
    .nwHdr-top .col-md-5.logo_col.text-right{
        max-width: 50%;
    }
    .languageDiv{
        padding-left: 15px;
    }
    #google_translate_element select {
        font-size: 14px;
        max-width: 148px;
    }
    .languageDiv h5{
        font-size: 13px;
    }
    .iv-title {
        font-size: 25px !important;
    }
    .iv-sub-title{
        padding-top: 15px !important;
    }
    .membershipDisplay h5{
        font-size: 18px;
    }
    h1 {
        font-size: 20px;
    }
    .cancleOrderPage .custom-btn{
        flex-wrap: wrap;
    }
    .cancleOrderPage .co-btn{
        width: 100% !important;
    }
    a.main-menu-btn.link-btn span {
        display: none;
    }
}
