.mb-head h1{
    font-size: 37px;
    text-align: center;
    color: #ff0000;
    font-weight: 700;
    text-transform: capitalize;
}
.mb-body {
    padding: 50px 15px;
}
.mb-left img{
    width: 285px;
    height: 285px;
}
.mb-right{
    text-align: left;
}
.mb-right {
    font-size: 22px;
}
.mr-inner {
    padding-bottom: 20px;
}
.mr-inner ul{
    margin-top: 20px;
}
.mb-link a{
    font-size: 22px;
    font-weight: 600;
}
.mb-con .btn {
    background-color: #37a8df;
    color: #fff;
    text-align: center;
    cursor: pointer;
    font-size: 25px;
    font-weight: 700;
    border: 2px solid;
    border-radius: 8px;
    padding: 20px 20px;
    width: 450px;
    max-width: 100%;
}

.mb-con .btn:hover {
    border: 2px solid #37a8df;
    color: #37a8df;
}

.mb-sec + h4 {
    padding-left: 10px;
    padding-right: 10px;
}

@media screen and (max-width: 767.5px){
    .mb-head h1{
        font-size: 25px;
    }
    .mb-right {
        font-size: 17px;
    }
    .mb-con .btn {
        width: 100%;
        height: 90px;
    }
    .mb-left {
        padding-bottom: 20px;
    }
    .mb-link {
        text-align: center;
    }
    .mb-link a{
        font-size: 16px;
    }
    .mb-con .btn {
        font-size: 18px;
        height: auto;
        padding: 15px;
    }
    .mb-sec + h4 {
        font-size: 18px;
    }
}

.cp{
    cursor: pointer;
}